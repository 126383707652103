import { Box, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3, 0, 3, 0),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2, 0, 2, 0),
  },
  '& .IVHSButtonContiner': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  '& .IPTBtn': {
    borderRadius: '2px',
    fontSize: theme.spacing(2),
  },
  '& .IPTMenuOption': {
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.grey.A000,
      boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.25)',
    },
    fontSize: theme.spacing(2.5),
  },
  '& .IPTOptiondBtnsDesktop': {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
    gap: theme.spacing(2),
  },
  '& .IPTOptiondBtn': {
    borderRadius: theme.typography.pxToRem(5),
    padding: theme.typography.pxToRem(6),
    border: `1px solid ${theme.palette.grey[100]}`,
    color: theme.palette.secondary.light,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.IPTMenuItem': {
    padding: theme.spacing(1.125, 2),
    '& .IPTItemIcon': {
      color: theme.palette.secondary.light,
      minWidth: 'auto',
      paddingRight: theme.spacing(1),
      '& .MuiSvgIcon-root': {
        fontSize: theme.spacing(2.7),
      },
    },
    '& .IPTListItemText': {
      color: theme.palette.grey[500],
    },
  },
}));

export default StyledBox;
