import { Typography, Box, styled } from '@mui/material';
import React from 'react';
import { MainTitleWrapper } from 'views/common/components/UI/MainTitleWrapper';
import Breadcrumbs from 'views/common/components/UI/Breadcrumbs';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  color: theme.palette.secondary.dark,
}));
const PageTitle = (props) => {
  const {
    mainTitleObject,
    date,
    location,
    breadcrumbsObject,
    shareList,
    iconBackgroundColor,
    locationTypoComponent = 'p',
    titlewidth,
    mobileTitleWidth,
  } = props;

  return (
    <>
      {breadcrumbsObject && (
        <Box
          sx={{
            marginBottom: { sm: 1, md: 1, lg: 2 },
          }}>
          <Breadcrumbs
            data={breadcrumbsObject}
            iconBackgroundColor={iconBackgroundColor}
          />
        </Box>
      )}
      <MainTitleWrapper
        mainTitleObject={mainTitleObject}
        shareList={shareList}
        titlewidth={titlewidth}
        mobileTitleWidth={mobileTitleWidth}
      />
      {date && (
        <StyledTypography component="p" className="PTDate">
          {date}
        </StyledTypography>
      )}
      {location && (
        <StyledTypography component={locationTypoComponent}>
          {location}
        </StyledTypography>
      )}
    </>
  );
};

export { PageTitle };
