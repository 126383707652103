import React from 'react';
import {
  Typography, Grid, Box, styled,
} from '@mui/material';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .tourName': {
    fontWeight: '300',
  },
  '& .nightsCountBox': {
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column',
    },
  },
  '& .MTRTitle': {
    color: `${theme.palette.secondary.dark} !important`,
  },
}));

const ShareList = dynamic(
  () => import('views/common/components/UI/ShareList'),
  {
    ssr: false,
  },
);

const MainTitleWrapper = (props) => {
  const {
    mainTitleObject,
    shareList = true,
    titlewidth = 9,
    mobileTitleWidth = 10,
  } = props;

  const { t } = useTranslation('fe_er_motorcycle_tours');

  return (
    <StyledGrid container>
      <Grid item xs={mobileTitleWidth} lg={titlewidth}>
        <Typography500
          variant="h2"
          component="h1"
          className="MTRTitle"
          dangerouslySetInnerHTML={{
            __html: mainTitleObject.name,
          }}
        />

        {(mainTitleObject.tour_type || mainTitleObject.days_count) && (
          <Box my={1} display="flex" className="nightsCountBox">
            {mainTitleObject.tour_type && (
              <Typography variant="h5" component="p" className="tourName">
                {`${mainTitleObject.tour_type.name} ${t(
                  'fe_er_motorcycle_tours:tour',
                )} - `}
              </Typography>
            )}
            {mainTitleObject.days_count && (
              <Typography500 variant="h5" component="p">
                {`${mainTitleObject.days_count} ${t(
                  'fe_er_motorcycle_tours:activity_day',
                )}/ `}

                {`${mainTitleObject.days_count - 1} ${t(
                  'fe_er_motorcycle_tours:nights',
                )}`}
              </Typography500>
            )}
          </Box>
        )}
      </Grid>
      {shareList && (
        <Grid item xs={2} lg={3} align="right">
          <ShareList />
        </Grid>
      )}
    </StyledGrid>
  );
};

export { MainTitleWrapper };
