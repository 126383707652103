import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Box,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import StyledBox, {
  StyledMenuItem,
} from 'views/common/components/UI/InfoPageTitle/InfoPageTitle.styles';
import {
  FloatingButtonWithFloatingDialog,
  PageTitle,
} from 'views/common/components';
import Breadcrumbs from 'views/common/components/UI/Breadcrumbs';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const InfoPageTitle = (props) => {
  const {
    mainTitleObject,
    breadcrumbsObject,
    buttonText,
    FloatingButtonWithFloatingDialogProp = true,
    handelJoinNowClick,
    date,
    socialIcon = false,
    className,
    reservationData,
    mobileTitleWidth = 12,
    widgetType,
    menuOptionItems = false,
  } = props;
  const [openMainWidgetDialog, setOpenMainWidgetDialog] = useState(false);
  const handleOpenMainWidgetDialog = () => {
    setOpenMainWidgetDialog(true);
  };
  const handleCloseMainWidgetDialog = () => {
    setOpenMainWidgetDialog(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledBox className={className}>
      <Container maxWidth="xl">
        {breadcrumbsObject && (
          <Box sx={{ marginBottom: { sm: 1, md: 1, lg: 2 } }}>
            <Breadcrumbs data={breadcrumbsObject} />
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid
            item
            lg={8}
            md={menuOptionItems ? 8 : 12}
            xs={menuOptionItems ? 10 : 12}>
            <PageTitle
              mainTitleObject={mainTitleObject}
              shareList={socialIcon}
              titlewidth="12"
              mobileTitleWidth={mobileTitleWidth}
              date={date}
            />
          </Grid>
          <Grid
            lg={4}
            md={menuOptionItems ? 4 : 12}
            xs={menuOptionItems ? 2 : 12}
            className="IVHSButtonContiner">
            {FloatingButtonWithFloatingDialogProp ? (
              <FloatingButtonWithFloatingDialog
                text={buttonText}
                handleFloatingPointClick={handleOpenMainWidgetDialog}
                widgetDialogData={{ open: openMainWidgetDialog }}
                handleWidgetDialogClose={handleCloseMainWidgetDialog}
                reservationData={reservationData}
                floatingDialog
                widgetType={widgetType}
              />
            ) : (
              <Button
                className="IPTBtn"
                onClick={handelJoinNowClick}
                variant="contained"
                disableElevation>
                {buttonText}
              </Button>
            )}
            {menuOptionItems && (
              <>
                <Box className="showTABLET">
                  <IconButton
                    className="IPTMenuOption"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MoreVertIcon fontSize="inherit" />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                      style: {
                        width: '17rem',
                        borderRadius: 0,
                        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.10)',
                      },
                    }}>
                    {menuOptionItems.map((menuOptionItem) => (
                      <StyledMenuItem
                        className="IPTMenuItem"
                        onClick={() => {
                          menuOptionItem.onClick();
                          handleClose();
                        }}
                        key={menuOptionItem}>
                        <ListItemIcon className="IPTItemIcon">
                          {menuOptionItem.icon}
                        </ListItemIcon>
                        <ListItemText className="IPTListItemText">
                          {menuOptionItem.text}
                        </ListItemText>
                      </StyledMenuItem>
                    ))}
                  </Menu>
                </Box>
                <Box className="showDESKTOP IPTOptiondBtnsDesktop">
                  {menuOptionItems.map((menuOptionItem) => (
                    <Box key={menuOptionItem}>
                      <IconButton
                        className="IPTOptiondBtn"
                        onClick={() => {
                          menuOptionItem.onClick();
                        }}>
                        {menuOptionItem.icon}
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};

export { InfoPageTitle };
