import React from 'react';
import { ReservationFloatingButton } from 'views/common/components';
import dynamic from 'next/dynamic';

const MainWidgetDialog = dynamic(() => import('views/common/components/UI/MainWidgetDialog').then(
  (file) => file.MainWidgetDialog,
));

const FloatingButtonWithFloatingDialog = (props) => {
  const {
    text,
    widgetDialogData,
    handleFloatingPointClick,
    handleWidgetDialogClose,
    scrollValue,
    reservationData,
  } = props;

  return (
    <>
      <ReservationFloatingButton
        text={text}
        scrollValue={scrollValue}
        handleClick={handleFloatingPointClick}
      />
      {widgetDialogData.open && (
        <MainWidgetDialog
          scrollValue={scrollValue}
          open={widgetDialogData.open}
          handleClose={handleWidgetDialogClose}
          reservationData={reservationData}
          container={() => document.getElementById('floatingButton')}
          floatingDialog
          renderFullWidgetByForce
          {...widgetDialogData}
          {...props}
        />
      )}
    </>
  );
};

export { FloatingButtonWithFloatingDialog };
